


body{
  background-color: #00343e;
}

.pending{
  -webkit-box-shadow:inset 10px 0 0 0 #ffff00;
  -moz-box-shadow:inset 10px 0 0 0 #ffff00;
  box-shadow:inset 10px 0 0 0 #ffff00;
}

.verified{
  -webkit-box-shadow:inset 10px 0 0 0 #358f35;
  -moz-box-shadow:inset 10px 0 0 0 #358f35;
  box-shadow:inset 10px 0 0 0 #358f35;
}

.processing{
  -webkit-box-shadow:inset 10px 0 0 0 #ff0000;
  -moz-box-shadow:inset 10px 0 0 0 #ff0000;
  box-shadow:inset 10px 0 0 0 #ff0000;
}

.marked_to_send{
  -webkit-box-shadow:inset 10px 0 0 0 #95df87;
  -moz-box-shadow:inset 10px 0 0 0 #95df87;
  box-shadow:inset 10px 0 0 0 #95df87;
} 

.sent{
  -webkit-box-shadow:inset 10px 0 0 0 white;
  -moz-box-shadow:inset 10px 0 0 0 white;
  box-shadow:inset 10px 0 0 0 white;
}

.document-list-item , #doc-table > thead{
  border-bottom: 1px solid black;
  height: 7vh;
}

#doc-table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  background-color: white;
  border-radius: 5px;
  
}

#doc-table > thead{
  background-color: #00343e;
  color: wheat;
  
}
thead{
  background-color:  white;
  color: wheat;
  
}

table td{
  text-align: center;
  vertical-align: middle;
}

table th{
  font-weight: bold;
  text-align: center;
  
}


td a{
  font-size: 1.3em;
}

.container{
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  margin-right: 10%;
}

.options{
  margin-bottom: 30px;
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
}

tr:nth-child(even){
  background-color: rgb(240, 245, 239);
}
.option{
  flex: 1;
}

.upload-button, .delete-button, .marked-documents-download-button{
  float: right;
}

.doc-link > a:hover{
  text-decoration: none;
}

.pagination-block{
  display: flex;
  flex-direction: row;
  background-color: white;
  justify-content: center;
}

.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}

.pagination a {
  border-radius: 5px;
  transition: background-color .10s;
}

.pagination a.active {
  border-radius: 5px;
  background-color: #358f35;
  color: white;
}

.disabled-link {
  pointer-events: none;
}

.newsweb-link{
  font-size: 0.7em;
}
