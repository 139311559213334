.navbar {
  border-radius: 0px;
}

.navbar > a {
    text-decoration: none;
  }

.login {
    max-width: 600px;
    padding: 20px;
    margin: 10% auto 0;
}