/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: none;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a{
  text-decoration: none;
}


html {
  width: 100%;
  height: 100%;
  font-size: 100%;
  font-family: Arial;
}

h1 {
  font-family: Arial;
  font-size: 3rem;
  color: #828282;
  padding-bottom: .6rem;
}

h2 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2.5rem;
  color: #828282;
}

h3 {
  font-family: Arial;
  font-size: 2rem;
  color: #828282;
}

h4 {
  font-family: Arial;
  font-size: 1.5rem;
  color: #828282;
}

h5 {
  font-family: Arial;
  font-size: 1rem;
  color: #828282;
}

p {
  font-family: Arial;
  font-size: 1rem;
  line-height: 2rem;
  color: #828282;
}

a {
  font-family: Arial;
  font-size: 1rem;
}

a:hover {
  color: #828282;
  cursor: pointer;
}

b, strong {
  font-weight: bold;
}

code {
  padding: 2px 4px;
}

label {
  font-family: Arial;
  color: #828282;
}